<template>
  <AppHeader>
    <template #navigation>
      <nuxt-link
        :to="localePath('/admin/shifts')"
        exact
      >
        Služby
      </nuxt-link>
      <nuxt-link
        :to="localePath('/admin/users')"
      >
        Uživatelé
      </nuxt-link>
      <nuxt-link
        v-if="useAuthStore().user?.lang !== 'ua'"
        :to="localePath('/admin/ratings')"
      >
        Hodnocení
      </nuxt-link>
      <nuxt-link
        v-if="useAuthStore().user?.lang !== 'ua'"
        :to="localePath('/admin/stats')"
      >
        Statistiky
      </nuxt-link>
      <nuxt-link
        :to="localePath('/admin/activities')"
      >
        Aktivity
      </nuxt-link>
      <nuxt-link
        v-if="useAuthStore().user?.lang !== 'ua'"
        :to="localePath('/admin/alerts')"
      >
        Upozornění
      </nuxt-link>
    </template>
  </AppHeader>
</template>
