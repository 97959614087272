<template>
  <main
    :class="useRoute().meta?.fillHeight ? 'FillHeight' : ''"
  >
    <div
      v-if="useRoute().meta?.lightBackground"
      class="layout-fixed-bg tw-bg-light"
    />

    <AppHeaderAdmin />

    <slot />

    <!-- global dialogs -->
    <ConfirmEmergencyDialog />
  </main>
</template>

<script setup>
onMounted(() => {
  useNuxtApp().$store.dispatch('requests/getRequestStates');
  useNuxtApp().$store.dispatch('operator/operators/getOperators');
});
</script>

<style scoped>
main {
  &.FillHeight {
    padding-bottom: 0;
    height: 100vh;
    overflow: hidden;

    display: flex;
    flex-direction: column;
  }
}
</style>
